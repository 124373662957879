exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artikel-index-js": () => import("./../../../src/pages/Artikel/index.js" /* webpackChunkName: "component---src-pages-artikel-index-js" */),
  "component---src-pages-artikel-simple-fails-less-js": () => import("./../../../src/pages/Artikel/SimpleFailsLess.js" /* webpackChunkName: "component---src-pages-artikel-simple-fails-less-js" */),
  "component---src-pages-artikel-solid-principles-js": () => import("./../../../src/pages/Artikel/SolidPrinciples.js" /* webpackChunkName: "component---src-pages-artikel-solid-principles-js" */),
  "component---src-pages-artikel-test-driven-development-js": () => import("./../../../src/pages/Artikel/TestDrivenDevelopment.js" /* webpackChunkName: "component---src-pages-artikel-test-driven-development-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/Datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/Impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-index-js": () => import("./../../../src/pages/Kontakt/index.js" /* webpackChunkName: "component---src-pages-kontakt-index-js" */),
  "component---src-pages-projekte-index-js": () => import("./../../../src/pages/Projekte/index.js" /* webpackChunkName: "component---src-pages-projekte-index-js" */),
  "component---src-pages-ueber-mich-c-sharp-dot-net-entwicklung-js": () => import("./../../../src/pages/UeberMich/CSharpDotNetEntwicklung.js" /* webpackChunkName: "component---src-pages-ueber-mich-c-sharp-dot-net-entwicklung-js" */),
  "component---src-pages-ueber-mich-delphi-entwicklung-js": () => import("./../../../src/pages/UeberMich/DelphiEntwicklung.js" /* webpackChunkName: "component---src-pages-ueber-mich-delphi-entwicklung-js" */),
  "component---src-pages-ueber-mich-high-speed-websites-js": () => import("./../../../src/pages/UeberMich/HighSpeedWebsites.js" /* webpackChunkName: "component---src-pages-ueber-mich-high-speed-websites-js" */),
  "component---src-pages-ueber-mich-lebenslauf-js": () => import("./../../../src/pages/UeberMich/Lebenslauf.js" /* webpackChunkName: "component---src-pages-ueber-mich-lebenslauf-js" */),
  "component---src-pages-ueber-mich-meine-entwicklungsphilosophie-js": () => import("./../../../src/pages/UeberMich/MeineEntwicklungsphilosophie.js" /* webpackChunkName: "component---src-pages-ueber-mich-meine-entwicklungsphilosophie-js" */),
  "component---src-pages-ueber-mich-steckbrief-js": () => import("./../../../src/pages/UeberMich/Steckbrief.js" /* webpackChunkName: "component---src-pages-ueber-mich-steckbrief-js" */),
  "component---src-templates-projekt-template-js": () => import("./../../../src/templates/projektTemplate.js" /* webpackChunkName: "component---src-templates-projekt-template-js" */)
}

